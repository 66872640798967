

















import Vue from 'vue'
import Component from 'vue-class-component'
import TransferSheetHandler from '@/lib/TransferSheetHandler'
import BaseCurrencyInfo from '@/components/remittance/BaseCurrencyInfo.vue'

@Component({
  components: { BaseCurrencyInfo }
})
export default class TransferDownload extends Vue {
  download (): void {
    const sheetHandler: TransferSheetHandler = new TransferSheetHandler()
    const sheetJson = sheetHandler.makeSheetJson()
    sheetHandler.setSheetJsonToExport(sheetJson)
    if (!Object.keys(sheetJson).length) this.$message('error')
    else sheetHandler.saveSheetByJson('transfer.xlsx', {})
  }
}
